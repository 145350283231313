

.controller {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  /*background: #394a52;*/
  /*padding: 40px;*/
  border-radius: 10px;
  box-shadow: 0 16px 40px -5px rgba(0, 0, 0, 0.5);
  font-size: 3em;
  z-index: 10000;
  pointer-events: all;
}


